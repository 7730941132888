import { Contact, useContactStore } from './contact';
import { defineStore } from 'pinia';
import { json } from '@sahnee/ajax';
import { OptionalKeys } from '@/utils/object';
import { BestinformedVersion, Product, useVersionStore } from './version';
import { useEditionStore } from './edition';
import { User, useUserStore } from './user';

interface State {
  customers: Customer[];
  loading: number;
  loaded: boolean;
}

/**
 * A customer.
 *
 * TODO: Maybe change structure as follows **(of course this *heavily* depends on which keys are necessary as they are!)**
 * replace:
 * ```
 * […]
 * productselection: [
 *  'bestinformed',
 *  'bestproxy',
 *  'hosting',
 *  'bestzero'
 * ],
 * […]
 * ```
 * with:
 * ```
 * […]
 * products: {
 *  bestinformed: {
 *    enabled: […],
 *    licenses: {
 *      cals: [-1 for unlimited, else cals],
 *      mobile: […],
 *      cluster: […],
 *    },
 *    hosting: {
 *      enabled: […],
 *      cancellation: {
 *        date: […],
 *        enabled: […],
 *        name: […],
 *        to: […],
 *        type: […],
 *      },
 *      runtimeTo: […],
 *      […]
 *    },
 *    […]
 *  },
 *  bestproxy: {
 *    enabled: […],
 *    cores: […],
 *    ram: […],
 *    runtimeTo: […],
 *    webaccess: {
 *      enabled: […],
 *      count: […],
 *    },
 *    hosting: {
 *      enabled: […],
 *      cancellation: {
 *        date: […],
 *        enabled: […],
 *        name: […],
 *        to: […],
 *        type: […],
 *      },
 *      runtimeTo: […],
 *      […]
 *    },
 *    […]
 *  },
 *  bestzero: {
 *    enabled: […],
 *    […]
 *  },
 * },
 * […]
 * ```
 * So every product has its own key in the `products` object which stores all product specific information.
 */
export interface Customer {
  _id: string;
  _rev: string;
  customerNumber: string;
  doc_type: 'customer';
  token: string;
  company: string;
  pilot: boolean;
  street1: string;
  street2: string;
  street3: string;
  type: 'customer';
  zip_code: string;
  city: string;
  country: string;
  editionProduct: string; // editionProduct = Apps | oldEdition | bestzero
  addOnsProduct: string;
  numberOfLicences: number;
  supportFrom: string;
  supportTo: string;
  specials: string;
  accountid: number;
  subscription: boolean;
  apps_full: Record<string, true>; // TODO: Ask Michi if this is really necessary
  apps: string[];
  editionApps: string;
  reseller: boolean;
  supportCancel: boolean;
  containsReseller: string;
  bestproxyruntimeto: string;
  bestproxywebaccess: number;
  bestproxyram: number;
  bestproxycores: number;
  webaccess: boolean;
  bestproxy: boolean;
  bestinformed: boolean;
  productselection: string | string[]; // TODO: Why can this be a string, shouldn't this only be an array?
  mobilecals: number;
  clusternodes: number;
  commentfield: string;
  dcpc: boolean;
  canceledTo: string;
  unlimited_cals: boolean;
  hosting_type: string;
  hostingruntimeto: string;
  hosting: boolean;
  canceled_type: string;
  canceled_date: string;
  canceled_name: string;
  hosting_canceled_type: string;
  hosting_canceled_date: string;
  hosting_canceled_name: string;
  hosting_canceledTo: string;
  hostingCancel: boolean;
  // PRODUCTS
  products: {
    bestinformed: {
      version: BestinformedVersion;
      installedVersion: Record<string, string[]>;
    };
    bestproxy: {
      installedVersion: Record<string, string[]>;
    };
    bestzero: {
      installedVersion: Record<string, string[]>;
    };
  };
}

export interface FutureCustomer {
  _id: string;
  _rev: string;
  doc_type: 'customer';
  // GENERAL
  cancelled: Cancelled;
  company: string;
  customerNumber: string;
  created: {
    user: string;
    date: string;
  }
  edited: {
    user: string;
    date: string;
  }
  dataProcessingContract: boolean;
  isPilot: boolean;
  isReseller: boolean;
  notes: string;
  resellerId: string;
  support: Runtime;
  vTigerId: number;
  // ADDRESS
  address: {
    street1: string;
    street2: string;
    street3: string;
    zip_code: string;
    city: string;
    country: string;
  }
  // PRODUCTS
  products: {
    // PRODUCTS > BESTINFORMED
    bestinformed: {
      active: boolean;
      apps: string[];
      cancelled: Cancelled;
      edition: string;
      installedVersion: { server: string; client: string; };
      licences: { cals: number; mobile: number; cluster: number; };
      runtime: Runtime;
      licenseType: 'subscription' | 'purchase';
      version: BestinformedVersion;
      // PRODUCTS > BESTINFORMED > HOSTING
      hosting: {
        active: boolean;
        runtime: Runtime;
        cancelled: Cancelled;
      };
    };
    // PRODUCTS > BESTPROXY
    bestproxy: {
      active: boolean;
      cancelled: Cancelled;
      hasWebaccess: boolean;
      installedVersion: string;
      licenses: { cores: number; ram: number; webaccess: number; };
      runtime: Runtime;
      // PRODUCTS > BESTPROXY > HOSTING
      hosting: {
        active: boolean;
        runtime: Runtime;
        cancelled: Cancelled;
      };
    };
    // PRODUCTS > BESTZERO
    bestzero: {
      active: boolean;
      installedVersion: string;
      licences: { cals: number; };
      runtime: Runtime;
    };
  };
}

interface Runtime {
  from: string;
  to: string;
}

interface Cancelled {
  active: boolean;
  to: string;
  date: string;
  name: string;
  type: string;
}

export const defaultCustomer: Omit<Customer, '_id' | '_rev'> = {
  customerNumber: '',
  doc_type: 'customer',
  token: '',
  company: '',
  pilot: false,
  street1: '',
  street2: '',
  street3: '',
  type: 'customer',
  zip_code: '',
  city: '',
  country: '',
  editionProduct: '',
  addOnsProduct: '',
  numberOfLicences: 1,
  supportFrom: '',
  supportTo: '',
  specials: '',
  accountid: 0,
  subscription: false,
  apps_full: {},
  apps: [],
  editionApps: '',
  reseller: false,
  supportCancel: false,
  containsReseller: '',
  bestproxyruntimeto: '',
  bestproxywebaccess: 0,
  bestproxyram: 1,
  bestproxycores: 1,
  webaccess: false,
  bestproxy: false,
  bestinformed: false,
  productselection: [],
  mobilecals: 0,
  clusternodes: 0,
  commentfield: '',
  dcpc: false,
  canceledTo: '',
  unlimited_cals: false,
  hosting_type: '',
  hostingruntimeto: '',
  hosting: false,
  canceled_type: '',
  canceled_date: '',
  canceled_name: '',
  hosting_canceled_type: '',
  hosting_canceled_date: '',
  hosting_canceled_name: '',
  hosting_canceledTo: '',
  hostingCancel: false,
  products: {
    bestinformed: {
      version: '',
      installedVersion: {},
    },
    bestproxy: {
      installedVersion: {},
    },
    bestzero: {
      installedVersion: {},
    },
  },
}

function prepare(customer: Partial<Customer>) {
  const fullCustomer: Partial<Customer> = { ...defaultCustomer, ...customer };
  const defaultKeys = ['_id', '_rev', ...Object.keys(defaultCustomer)];
  const additionalKeys = Object.keys(fullCustomer).filter(key => !defaultKeys.includes(key)) as (keyof Customer)[];
  for (const key of additionalKeys) {
    delete fullCustomer[key];
  }

  // Add version 6 edtion to `apps` array & `apps_full` object
  if (fullCustomer.editionProduct === 'Apps' && fullCustomer.editionApps !== undefined && fullCustomer.apps !== undefined && fullCustomer.apps_full !== undefined) {
    if (!fullCustomer.apps.includes(fullCustomer.editionApps)) {
      fullCustomer.apps.push(fullCustomer.editionApps);
    }
    if (!Object.keys(fullCustomer.apps_full).includes(fullCustomer.editionApps)) {
      fullCustomer.apps_full[fullCustomer.editionApps] = true;
    }
  }

  return fullCustomer;
}

/**
 * The customer store.
 */
export const useCustomerStore = (preload = false) => {
  // Define a local store reference
  const useStore = defineStore('customer', {
    ///-------------------------------------------------------------------
    /// STATE
    ///-------------------------------------------------------------------
    state: (): State => {
      return {
        customers: [],
        loading: 0,
        loaded: false,
      }
    },
    ///-------------------------------------------------------------------
    /// GETTERS
    ///-------------------------------------------------------------------
    getters: {
      /**
       * Checks if a given email is already saved in another contact document.
       * @param email The email.
       * @param excludedIds The contact IDs to exclude.
       * @returns Whether the given email already exists in another contact or not.
       */
      companyExists: (state: State) => {
        return (company: string, excludedIds: string | string[] = []) => {
          if (!Array.isArray(excludedIds)) {
            excludedIds = [excludedIds];
          }
          return state.customers
            .filter(customer => !excludedIds.includes(customer._id))
            .map(customer => customer.company.toLowerCase().trim())
            .includes(company.toLowerCase().trim());
        }
      },
      /**
       * Checks if the store is currently loading.
       * @returns Whether the store is loading or not.
       */
      isLoading: (state: State) => {
        return () => state.loading > 0;
      },
      /**
       * Search for customers by their ID.
       * @param ids The ID or an array of IDs of the customers.
       * @returns An array of customers from the store.
       */
      getById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const customers = state.customers.filter(customer => ids.includes(customer._id))
          return ids.length === 1 ? customers[0] : customers;
        }) as ((ids: string | [string]) => Customer) & ((ids: string[]) => Customer[])
      },
      /**
       * Search for customers by their ID.
       * @param ids The ID or an array of IDs of the customers.
       * @returns An array of customers from the store.
       */
      getReseller: (state: State) => {
        return () => {
          return state.customers.filter(customer => customer.reseller);
        }
      },
      getVTigerBestinformedEdition: () => {
        return (customer: OptionalKeys<Customer, '_id' | '_rev'>) => {
          return (customer.editionProduct === 'Apps' ? customer.editionApps : customer.editionProduct) ?? '';
        }
      },
      getBestinformedVersion: () => {
        return async (customer: OptionalKeys<Customer, '_id' | '_rev'>) => {
          const versionStore = useVersionStore();
          await versionStore.load();

          const getVersion = (v: BestinformedVersion) => {
            return versionStore.findVersionByName( v, productVersions) as Product | undefined;
          }

          const productId = versionStore.findVersionByName('bestinformed')?._id ?? 'root';
          const productVersions = versionStore.getFlatSubtree(productId)
            .filter(product => product.doc_type === 'version_product' && product._id !== productId)
            .map(product => { delete product._children; return product })

          if (!customer.products.bestinformed.version) return customer.editionProduct === 'Apps' ? getVersion('6') : getVersion('5');
          return getVersion(customer.products.bestinformed.version);
        }
      },
      getBestinformedEdition() {
        return (customer: OptionalKeys<Customer, '_id' | '_rev'>) => {
          if (customer.editionProduct === 'Apps') {
            return (customer.products.bestinformed.version === '6' ? customer.editionApps : customer.editionProduct) ?? '';
          } else {
            return (customer.products.bestinformed.version === '5' ? customer.editionProduct : /^Alarm/.test(customer.editionProduct) ? 'alarm' : 'standard') ?? '';
          }
        }
      },
      getBestinformedEditionName() {
        return (customer: OptionalKeys<Customer, '_id' | '_rev'>) => {
          const editionStore = useEditionStore();
          const id = this.getBestinformedEdition(customer);
          return editionStore.getNameOf(id);
        }
      },
      getBestinformedApps() {
        return (customer: OptionalKeys<Customer, '_id' | '_rev'>) => {
          // Already version 6 -> return actual apps
          if (customer.editionProduct === 'Apps' || (customer.apps && customer.apps.length > 0)) return customer.apps || [];

          // Version 5 -> return apps that represents the current version 5 edition
          const editionStore = useEditionStore();
          const id = customer.editionProduct;
          const edition = editionStore.getById(id);
          if (!edition) return [];

          const [migrateId, { apps: migrateApps }] = Object.entries(edition.migrate['6'])[0]; // ['standard', { apps: […], excludedApps: […] }]
          const migrateEdition = editionStore.getById(migrateId);
          if (!migrateEdition) return [];

          return [
            ...new Set([
              ...migrateEdition.apps,
              ...(migrateApps || [])
            ])
          ];
        }
      },
      getBestinformedExcludedApps() {
        return (customer: OptionalKeys<Customer, '_id' | '_rev'>) => {
          const editionStore = useEditionStore();
          // Version 6
          if (customer.editionProduct === 'Apps') {
            const version6edition = editionStore.getById(customer.editionApps);
            if (!version6edition) return [];
            return version6edition.excludedApps || [];
          }
          // Version 5
          const verstion5Edition = editionStore.getById(customer.editionProduct);
          if (!verstion5Edition) return [];

          const [migrateId, { excludedApps: migrateExcludedApps }] = Object.entries(verstion5Edition.migrate['6'])[0];
          const migrateEdition = editionStore.getById(migrateId);
          if (!migrateEdition) return [];

          return [
            ...new Set([
              ...migrateEdition.excludedApps,
              ...(migrateExcludedApps || [])
            ])
          ];
        }
      },
      hasProduct: () => {
        return (customer: OptionalKeys<Customer, '_id' | '_rev'>, productId: string) => {
          const product = productId as keyof Customer;
          switch (productId) {
            case 'bestinformed': {
              return customer.editionProduct !== 'bestzero' && !!(customer[product] ||
                (Array.isArray(customer.productselection)
                  ? customer.productselection?.includes(productId)
                  : customer.productselection === productId) ||
                ((!customer.productselection || customer.productselection.length === 0) && (!!customer.pilot || customer.editionProduct))
              );
            }
            case 'bestproxy': {
              return customer.editionProduct !== 'bestzero' && !!(customer[product] ||
                (Array.isArray(customer.productselection)
                  ? customer.productselection?.includes(productId)
                  : customer.productselection === productId)
              );
            }
            case 'bestzero': {
              return !!(customer[product] ||
                customer.editionProduct === productId ||
                (Array.isArray(customer.productselection)
                  ? customer.productselection?.includes(productId)
                  : customer.productselection === productId)
              );
            }
            default: {
              return false;
            }
          }
        }
      }
    },
    ///-------------------------------------------------------------------
    /// ACTIONS
    ///-------------------------------------------------------------------
    actions: {
      /**
       * Initially loads the data by calling the erlang handler.
       * If the store has already been loaded, no more requests will be sent.
       */
      async load() {
        if (!this.loaded) {
          await this.reload();
        }
      },
      /**
       * Reload the store completely.
       * Calls the erlang handler and replaces the current state with the newly loaded one.
       */
      async reload() {
        this.loaded = false;
        this.loading++;
        try {
          const customers = await json<Customer[]>('/api/store/customers', {
            method: 'POST',
            json: {
              action: 'load',
            }
          });
          this.customers = customers;
          this.loaded = true;
        } finally {
          this.loading--;
        }
      },

      async readById(id: string) {
        this.loading++;
        try {
          const customer =  await json<Customer>('/api/store/customers', {
            method: 'POST',
            json: {
              action: 'read-by-id',
              id
            }
          });
          if (customer) {
            if (!this.customers.find(el => el._id === customer._id)) {
              this.customers.push(customer);
            }
            return {
              success: true,
              data: customer,
            };
          }
        } catch (error: unknown) {
        return {
          success: false,
          error: (error as Error).message,
        }
        } finally {
          this.loading--;
        }
      },
      /**
       * Creates a new customer.
       * The customer will automatically be saved in the database and added to the store.
       * @param customer The customer.
       */
      async create(customer: Partial<Customer>) {
        this.loading++;
        try {
          const created = await json<Customer>('/api/store/customers', {
            method: 'POST',
            json: {
              action: 'create',
              customer: prepare(customer),
            }
          });
          if (created) {
            this.customers.push(created);
            return {
              success: true,
              data: created,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting customer.
       * The customer will automatically be saved in the database and updated in the store.
       * @param customer The customer.
       */
      async update(customer: Partial<Customer>) {
        this.loading++;
        try {
          const updated = await json<Customer>('/api/store/customers', {
            method: 'POST',
            json: {
              action: 'update',
              customer: prepare(customer),
            }
          });

          if (updated) {
            const index = this.customers.findIndex((customer: Customer) => customer._id === updated._id);
            this.customers[index] = updated;
            return {
              success: true,
              data: updated,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting customer.
       * The customer will automatically be saved in the database and updated in the store.
       * @param customer The customer.
       */
      async updateCurrent(customer: Partial<Customer>, email: string) {
        this.loading++;
        try {
          const updated = await json<Customer>('/api/store/customers', {
            method: 'POST',
            json: {
              action: 'update',
              customer: prepare(customer),
              email
            }
          });

          if (updated) {
            const index = this.customers.findIndex((customer: Customer) => customer._id === updated._id);
            this.customers[index] = updated;
            return {
              success: true,
              data: updated,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Delete an exisiting customer.
       * The customer will automatically be deleted from the database and the store.
       * @param ids The ID or an array of IDs of customers to delete.
       */
      async delete(ids: string | string[]) {
        if (!Array.isArray(ids)) {
          ids = [ids];
        }
          this.loading++;
          try {
          const deletedDocs = await json<(Customer | Contact | User)[]>('/api/store/customers', {
            method: 'DELETE',
            json: {
              action: 'delete',
              ids
            }
          });
          for (const deleted of deletedDocs) {
            const contactStore = useContactStore();
            const userStore = useUserStore();
            let index;
            switch (deleted.doc_type) {
              case 'customer':
                index = this.customers.findIndex((customer: Customer) => customer._id === deleted._id);
                this.customers.splice(index, 1);
                break;
              case 'contact':
                index = contactStore.contacts.findIndex((contact: Contact) => contact._id === deleted._id);
                contactStore.contacts.splice(index, 1);
                break;
              case 'new_contact':
                index = userStore.users.findIndex((user: User) => user._id === deleted._id);
                userStore.users.splice(index, 1);
                break;
              default:
                break;
            }
          }
          return {
            success: true,
            data: deletedDocs,
          };
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      }
    }
  });

  // Preload the local store
  const store = useStore();
  if (preload) {
    store.load();
  }

  // Return the store reference
  return store;
};