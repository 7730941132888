import { json } from '@sahnee/ajax';
import { defineStore } from 'pinia';
import { BestinformedVersion } from './version';

interface State {
  editions: {
    bestinformed5: Edition[];
    bestinformed6: Edition[];
  };
  forceReload: boolean;
  loading: number;
  loaded: boolean;
}

/**
 * A edition.
 */
export interface Edition {
  /**
   * The ID of the edition.
   */
  _id: string;
  /**
   * The name of the edition.
   */
  name: string;
  /**
   * A list of app IDs which are included in this edition.
   */
  apps: string[];
  /**
   * A list of app IDs which cannot be used with this edition.
   */
  excludedApps: string[];
  /**
   * A collection of bestinformed versions each with a single edition and additional information this edition will be migrated to if a customer has the selected bestinformed version active
   * @example ```
   * const [id, { apps }] = Object.entries(edition.migrate['<BESTINFORMED_VERSION>'])[0];
   * const newEdition = editionStore.getById(id);
   * const migratedEdition = { ...newEdition, apps: [...new Set([...(newEdition?.apps || []), ...(apps || [])].filter(app => app !== id))] };
   * ```
   */
  migrate: Record<Exclude<BestinformedVersion, ''>, Record<Edition['_id'], Partial<Edition>>>;
  /**
   * A collection of bestinformed versions and their respective editions and additional information this edition can be upgraded to
   */
  upgrade: Record<Exclude<BestinformedVersion, ''>, Record<Edition['_id'], Partial<Edition>>>;
}

/**
 * The edition store.
 */
export const useEditionStore = (preload = false) => {
  // Define a local store reference
  const useStore = defineStore('edition', {
    ///-------------------------------------------------------------------
    /// STATE
    ///-------------------------------------------------------------------
    state: (): State => {
      return {
        editions: {
          bestinformed5: [],
          bestinformed6: []
        },
        forceReload: true,
        loading: 0,
        loaded: false,
      }
    },
    ///-------------------------------------------------------------------
    /// GETTERS
    ///-------------------------------------------------------------------
    getters: {
      /**
       * Checks if the store is currently loading.
       * @returns Whether the store is loading or not.
       */
      isLoading: (state: State) => {
        return () => state.loading > 0;
      },
      /**
       * Searches for the edition with the given ID and returns its name.
       * @param id The ID of the edition.
       * @returns The name of the edition.
       */
      getById: (state: State) => {
        return (id: string) => {
          return [...state.editions.bestinformed5, ...state.editions.bestinformed6].find(edition => edition._id === id);
        }
      },
      getNameOf() {
        return (id: string) => {
          if (/^(Infoband\s)?Basis/i.test(id)) { return this.getById('Basis')?.name || '' }
          if (/^(Infoband\s)?Enterprise/i.test(id)) { return this.getById('Enterprise')?.name || '' }
          if (/^(Infoband\s)?Profess/i.test(id)) { return this.getById('Professional')?.name || '' }
          return this.getById(id)?.name || ''
        }
      },
      getBestinformedVersionById: (state: State) => {
        return (id: string) => {
          return state.editions.bestinformed5.find(edition => edition._id === id) ? 5 : 6;
        }
      },
    },
    ///-------------------------------------------------------------------
    /// ACTIONS
    ///-------------------------------------------------------------------
    actions: {
      /**
       * Initially loads the data by calling the erlang handler.
       * If the store has already been loaded, no more requests will be sent.
       */
      async load() {
        if (!this.loaded) {
          await this.reload();
        }
      },
      /**
       * Reload the store completely.
       * Calls the erlang handler and replaces the current state with the newly loaded one.
       */
      async reload() {
        this.loaded = false;
        this.loading++;
        try {
          const response = await json<{ bestinformed5: Edition[], bestinformed6: Edition[] }>('/api/store/editions', {
            method: 'POST',
            json: {
              action: 'load',
            }
          });
          this.editions = {
            bestinformed5: response.bestinformed5,
            bestinformed6: response.bestinformed6,
          };
          this.loaded = true;
        } finally {
          this.loading--;
        }
      },
    }
  });

  // Preload the local store
  const store = useStore();
  if (preload) {
    store.load();
  }

  // Return the store reference
  return store;
};